.input {
    border-radius: 15px;
    border: solid grey;
    margin: 0;
    padding: 10px 10px;
    width: auto;
    min-width: 25%;
    box-shadow: 1px 2px 10px gray;
    outline: none;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    box-sizing: border-box;
    background-color: whitesmoke;
}

.input:hover {
    background-color: rgb(230, 230, 230);
}

.label {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 600;
    font-size: larger;
    width: auto;
}

.labelBox {
    margin: 40px 0 15px 0;
}