.top-template-image {
    height: auto;
    width: 100%;
    min-width: 100vw;
}

.top-template-image-container {
    position: relative;
    height: auto;
}

.bottom-template-image {
    height: auto;
    width: 100%;
}

.bottom-template-image-container {
    width: 100%;
}

.template-box {
    width: 100%;
    padding: 5vh 10vw;
}

.form-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    padding: 2vh 8vw;
}

.form-data .input {
    width: 50vw;
}

.form-data {
    width: 100%;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    justify-self: center;
    text-align: center;
    padding: 50px 0;
}

.form .button {
    margin-top: 70px;
    padding: 8px 50px;
}

.show-password-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 17px;
    width: 101%;
}

.show-password {
  font-size: 22px;
  margin-left: 20px;
  margin-top: 7px;
}

.emails-failed-list-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.emails-failed-list-show {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: black solid;
    border-radius: 5px;
    padding: 30px 20px;
}

.emails-failed-list-title-box {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.emails-failed-list-title-box svg {
    font-size: 30px;
    cursor: pointer;
}

.emails-failed-list-title-box h2 {
    margin: 20px !important;
}

.show-password:hover {
  color: rgb(45, 44, 44);
  cursor: pointer;
}

.template {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
}

.geolocation-buttons-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.geolocation-buttons-box a {
    margin-top: 30px;
    text-decoration: none;
    color: black;
    font-size: large;
}

.partners-detail {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
}

.partners-detail a {
    margin: 25px;
    display: flex;
    flex-direction: column;
    color: black;
    text-decoration: none;
}

.template-partners-box {
    padding: 50px 100px 220px 100px;
    background-image: url('../../images/Others//pandito-containers-tower.webp');
    background-size: cover;
    background-position: 50%;
}

.partners-template {
    background-color: rgba(234, 223, 167, 0.8);
    border-radius: 15px;
    margin-top: 50px;
}

.partners-detail:hover {
    transform: scale(1.02);
    transition: 0.3s;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.partners-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 20px 50px;
}

.partners-detail p {
    color: black;
    margin: 0;
}

.partners-title {
    background-color:  rgb(189, 156, 111, 0.8)  ;
    padding: 30px;
    border-radius: 15px 15px 0 0;
    text-align: center;
}

.partners-title h1 {
    margin-bottom: 0px !important;
}

/* PANDITO COLOR PALETE */
/* rgba(242,176,153,1) */ /* SALMON CLARO */
/* rgba(201,124,96,1)  */ /* SALMON OSCURO */

/* rgba(238,229,214,1) */ /* CREMA CLARO */
/* rgba(185,170,151,1) */ /* CREMA OSCURO */

/* rgba(232,223,179,1) */ /* AMARILLO CLARO */
/* rgba(189,170,111,1) */ /* AMARILLO OSCURO */

/* rgba(164,195,173,1) */ /* VERDE CLARO */
/* rgba(132,159,124,1) */ /* VERDE OSCURO */

/* rgba(119,206,225,1) */ /* AZUL CLARO */
/* rgba(23,123,172,1) */ /* AZUL OSCURO */

.partners-detail h2 {
    color:rgb(148, 64, 3);
    text-align: center;
}

.form-columns {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.form-group {
    flex: 1 1 35%;
    margin: 0 80px 0 80px;
}

.form-group-catering {
    width: 100%;
    flex: 1 1 35%;
}

.form-group .labelBox {
    display: block;
    margin-bottom: 5px;
}

.form-group .input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}
  
  
.geolocation {
    background-color: rgba(23,123,172,1);
    text-align: justify;
    padding: 20px 30px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.geolocation-buttons-box button {
    margin-top: 50px;
    padding: 15px 30px;
    display: flex;
    gap: 30px;
}

.geolocation-buttons-box p {
    font-size: 20px;
}

.loading-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: black;
    position: absolute;
    gap: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loading-icon {
    display: inline-block;
    font-size: 2rem;
    color: #000;
    animation: spin 1s linear infinite;
    line-height: 0;
    text-align: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.newsletter-template > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 50px;
}

.newsletter-template .form-columns > div {
    width: 70vw;
}

.button-selected {
    transform: scale(1.1);
    transition: transform 0.5s ;
}

.newsletter-template {
    padding: 100px 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    background-color: rgba(164,195,173,1);
}

.newsletter-buttons-and-table-box {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 50px;
}

.newsletter-email-sender form {
    padding: 0;
}

.newsletter-email-sender form .button {
    margin: 0;
}

.newsletter-buttons-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.newsletter-buttons-show {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.language-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.newsletter-table-box {
    flex: 3;
}

.newsletter-table-box h4 {
    text-align: center;
}

.newsletter-template h2 {
    margin: 50px;
    text-align: center;
}

.newsletter-no-users-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 150px 0;
    text-align: center;
}

.newsletter-no-users-box button {
    margin: 30px;
}

.is-email-verifyed {
    text-align: center !important;
}

.verifyed-true {
    color: green
}

.verifyed-false {
    color: red;
}

.update-language-template {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 90vh;
    gap: 0px;
}

.update {
    background-color: blanchedalmond;
}

.update-language-template .form {
    padding: 0;
}

.update-language-template .button {
    margin: 0;
    min-width: auto;
}

.template-box .admin-template {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 90vh;
}

.admin {
    background-color: blanchedalmond;
}

.admin-template .primary-button {
    display: flex;
    gap: 20px !important;
}

.admin-template .button {
    width: 20vw;
}

.admin-template .beige-button {
    padding: 13px 20px;
}

.geolocation-buttons-box span {
    font-weight: 600;
}

.about-us {
    background-image: url('../../images/BackgroundPage/vanilla-milk-ice-cream-cone.webp');
}

/* Locations styles */
.locations-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0;
    list-style-type: none;
    padding: 0;
    min-width: 20vw;
    margin: 0;
    overflow-y: auto;
    max-height: 65vh;
    justify-content: flex-start;
}

.locations-list .main-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 235, 205);
    padding: 15px 10px;
    border-radius: 8px;
    cursor: pointer;
    color: black;
    min-width: 100%;
    margin-bottom: 1em;
    font-weight: bold;
    /* flex: 1; */
}

.locations-list li a {
    text-decoration: none;
    color: black;
}

.locations-list li a span {
    margin-left: 10px;
}

.locations-list p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    cursor: pointer;
}

.locations-list .main-box:hover {
    background-color: rgba(255, 235, 205, 0.9);
}

.locations-img {
    height: 200px;
    width: 200px;
}

#opening-hours-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    list-style: none;
    font-size: 13px;
    padding: 0;
    /* margin: 10px 0; */
    min-height: 100px;
    color: black;
    font-weight: normal;
}

#opening-hours-info li {
    padding: 0;
    margin: 0;
}

#opening-hours-info .opening-hours-title {
    font-weight: bold;
} 

.locations-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 35px;
    background-color: rgb(56, 56, 56, 0.8);
    margin-top: 50px;
    padding: 50px;
    height: 80vh;
    border-radius: 10px;
}

#google-map {
    width: auto;
    min-width: 45vw;
    height: 65vh;
}

.pandito-icon-map {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: rgba(23,123,172,1);
    border-radius: 50%;
}

.pandito-icon-map img {
    height: 25px;
    width: 25px;
}

/* End of Locations styles */

.form-button button {
    min-width: 71vw;
}

.form-button {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.catering-button-show-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.img-uploader-box {
    cursor: pointer;
}

.img-uploader-box img {
    width: 800px;
    height: auto;
}

.row-style {
    padding-top: 30px;
}

.hr-section-separator {
    display: flex;
    margin-bottom: 50px;
    border: solid black 1px;
}

.sending-emails-box .loading-box {
    position: relative;
    margin-top: 50px;
}

@media (max-width: 1400px) {
    .img-uploader-box img {
        width: 600px;
    }
}

@media (max-width: 1000px) {
    .img-uploader-box img {
        width: 400px;
    }
}

@media (max-width: 700px) {
    .img-uploader-box img {
        width: 200px;
    }
    .emails-failed-list-title-box svg {
        font-size: 40px;
    }
    .emails-failed-list-title-box h2 {
        font-size: 25px;
    }
}

@media (max-width: 400px) {
    .img-uploader-box img {
        width: 150px;
    }
    .sending-emails-box .loading-box {
        margin-top: 100px;
    }
}

@media (min-width: 276px) and (max-width: 919px) {
    .locations-box {
        margin-left: 3em;
        margin-right: 3em;
        padding: 2em 1em;
        gap: 0.5em;
        overflow-y: auto;
        height: 80vh;
        max-width: 90vw;
    }

}

@media (max-width: 992px) {
    .partners-box {
        grid-template-columns: 1fr;
        padding: 10px 25px 50px 25px;
     }
    .template-partners-box {
        padding: 50px 50px 130px 50px;
    }
    .newsletter-buttons-and-table-box {
        flex-direction: column;
    }
    .language-buttons {
        flex-direction: column;
    }
}

@media (max-width: 555px) {
    #google-map {
        height: 40vh;
    }
    .partners-box {
        padding: 15px;
    }
    .partners-box a {
        margin: 15px;
    }
    .template-partners-box {
        padding: 25px 25px 100px 25px;
    }
    .admin-template .button {
        width: 80vw !important;
    }
    .emails-failed-list-show {
        padding: 10px;
    }
    .emails-failed-list-title-box h2 {
        font-size: 20px;
        margin: 20px 0 !important;
    }
}

@media (min-width: 1500px) {
    .top-template-image {
        height: auto;
        width: 100vw;
    }
}

@media (max-width: 1200px) {
    .form-group {
        flex: 1 100%;
        margin: 0;
    }
    .form-button button {
        min-width: 81vw;
    }
    .template-box .admin-template {
        flex-direction: column;
    }
    .admin-template .button {
        width: 50vw;
    }
}

.sara-luci-photos {
    max-width: 100%;
}

.menu-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 100px 0;
    padding: 30px;
    border-radius: 5px;
    gap: 30px;
}

.menu-images img {
    max-width: 100%;
    width: 600px;
    height: auto;
    border-radius: 5px;
}

.custom-orange-h1 {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translateX(-50%);
    color: rgb(255, 109, 24);
    border: solid 2px rgb(255, 109, 24);
    padding: 2px 2px;
    text-align: center;
    font-size: 3.5vw;
    font-weight: 900;
}

.custom-black-h1 {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translateX(-50%);
    color: black;
    border: solid 2px black;
    padding: 2px 2px;
    text-align: center;
    font-size: 3.5vw;
    font-weight: 900;
}

.custom-white-h1 {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    border: solid 2px white;
    padding: 2px 2px;
    text-align: center;
    font-size: 3.5vw;
    font-weight: 900;
}

.plum-link {
    color: rgb(210, 141, 210);
    text-decoration: none;
}

.carousel-1-item {
    display: inline;
}

.custom-black-p {
    font-weight: 600;
    color: black;
}

.description-box-row {
    display: flex;
    flex-direction: row;
    margin-top: 70px !important;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center !important;
    gap: 100px;
}

.no-gap {
    gap: 0;
}

.description-box p {
    padding-top: 20px;
}

/* PANDITO COLOR PALETE */
/* rgba(242,176,153,1) */ /* SALMON CLARO */
/* rgba(201,124,96,1)  */ /* SALMON OSCURO */

/* rgba(238,229,214,1) */ /* CREMA CLARO */
/* rgba(185,170,151,1) */ /* CREMA OSCURO */

/* rgba(232,223,179,1) */ /* AMARILLO CLARO */
/* rgba(189,170,111,1) */ /* AMARILLO OSCURO */

/* rgba(164,195,173,1) */ /* VERDE CLARO */
/* rgba(132,159,124,1) */ /* VERDE OSCURO */

/* rgba(119,206,225,1) */ /* AZUL CLARO */
/* rgba(23,123,172,1) */ /* AZUL OSCURO */
