.slider-child {
    scroll-snap-align: start;
    height: 100vh;
    width: 100%;
    scroll-snap-align: center;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    flex-direction: column;
    gap: 2em;
}

.slider-child:nth-child(1) {
    background-image: url('../../images/BackgroundPage/Cone-2.webp');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.slider-child:nth-child(2) {
    background-image: url('../../images/BackgroundPage/Sara-Luci-hug.webp');
    background-repeat: no-repeat;
    background-position: center 38%;
    background-size: cover;
}

.slider-child:nth-child(3) {
    background-image: url('../../images/BackgroundPage/pandito-charriot.webp');
    background-repeat: no-repeat;
    background-position: center 73%;
    background-size: cover;
}

.slider-child:nth-child(4) {
    background-image: url('../../images/BackgroundPage/pandito-flags.webp');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.message-manager-box {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start;
    align-items: flex-start;
    position: fixed;
    top: 65px;
    right: 0px;
    z-index: 9999;
    max-width: 400px;
    padding: 15px;
    background-color: rgb(31, 41, 55); 
    border-radius: 15px;
    gap: 20px !important;
    margin: 0 40px;
}

.message-manager-box p {
    margin: 0;
}

.error-icon {
    color: red;
    font-size: 22px;
}

.success-icon {
    color: green;
    font-size: 22px;
}

.manager-message {
    text-align: left;
    color: white;
}

.slider-child:nth-child(5) {
    background-image: url('../../images/BackgroundPage/Sin\ título.webp');
    background-repeat: no-repeat;
    padding: 10px;
    background-size: cover;
    background-position: 2% 95%;
}

.section-1-box h1 {
    margin-top: -100px;
    font-size: 150px;
    color: whitesmoke;
    text-align: center;
}

.section-1-box h3 {
    margin: 150px 10px 0px 10px;
    font-size: 25px !important;
    text-align: center;
}

.navbar-container button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-nav-bar {
    position: fixed;
    padding: 40px 20px 0px 20px;
    display: flex;
    bottom: 0;
    justify-content: space-between !important;
    overflow-x: scroll;
    overflow: hidden;
    z-index: 5;
    min-width: 100%;
    min-height: 50px;
}

.footer-nav-bar-toogled {
    background-color: rgba(23,123,172,0.9);
    height: 100%;
}

.navbar-toogle {
    display: flex;
    position: absolute;
    bottom: 20px;
}

.footer-nav-bar-templates {
    position: fixed;
    padding: 10px 20px 0px 20px;
    display: flex;
    bottom: 0;
    justify-content: space-between !important;
    overflow-x: scroll;
    overflow: hidden;
    z-index: 5;
    min-width: 100%;
    background-color: rgba(132,159,124,0.8);
    backdrop-filter: blur(5px);
    min-height: 55px;
}

.footer-nav-bar-templates-toggled {
    height: 100%;
}

.footer-nav-bar nav {
    min-width: 100%;
    background-color: transparent !important;
    display: flex;
    flex-direction: row;
}

.footer-nav-bar-templates nav {
    min-width: 100%;
}

.custom-footer-container {
    justify-content: center !important;
    gap: 20px;
}

.custom-footer-navbar {
    display: flex;
    margin: 0;
    min-width: 100%;
    flex-wrap: wrap;
    background-color: transparent !important;
    flex-direction: column !important;
    margin-bottom: 10px;
    gap: 10px;
}

.footer-navbar-col p {
    justify-content: space-around!important;
    text-align: justify;
    margin: 2px;
    font-size: 14px;
}

.footer-navbar-col a {
    justify-content: start !important;
    text-decoration: none;
}

.footer-navbar-col-buttons {
    display: flex;
    justify-content: start !important;
    flex-wrap: wrap;
    gap: 5em;
    margin-bottom: 0.8em;
}

.footer-navbar-col {
    text-align: center !important;
    display: flex;
    justify-content: center!important;
    /* flex-wrap: wrap; */
    flex-direction: column;
    /* max-height: 4em; */
}

.custom-footer-navbar a {
    display: flex;
    justify-content: center;
}

.custom-footer-navbar a svg {
    font-size: 2.3em;
}

.dropdown-lenguage-flag-box {
    display: flex !important;
    outline: none !important;
    border-radius: 10px !important;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 60px !important;
    height: auto;
    background-color: rgba(245, 245, 220, 0.1) !important;
    border: none !important;
}

.dropdown-lenguage-flag-box:hover {
    background-color: rgba(245, 245, 220, 0.5) !important;
}

.dropdown-lenguage-flag-box p {
    margin: 0;
    font-weight: 900;
}

.dropdown-item p {
    text-align: center !important;
    margin: 5px;
    font-weight: 900;
}

.dropdown-menu {
    min-width: 65px !important;
    margin-left: -3px !important;
    margin-top: 9px !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    padding: 0 !important;
}

.center-child-box {
    cursor: pointer;
    text-align: center;
  }
  
.center-child-box h3 {
    margin-top: 0.5em;
}

.custom-icon {
    width: 75px;
    height: 75px;
}

.dropdown-menu a:hover {
    background-color: rgba(245, 245, 220, 0.5) !important;
    border-radius: 5px;
}

.lenguage-flag {
    height: auto;
    width: 30px;
}

.description-footer-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

#tooltip-info {
    list-style: none;
    font-size: 13px;
    padding: 0;
    margin: 0;
}

.designed-by a {
    border-radius: 10px;
    font-size: 15px;
    padding: 5px 30px;
    text-decoration: none;
}

.designed-by a:hover {
    background-color: rgba(68, 68, 68, 0.3); 
    backdrop-filter: blur(5px);
    cursor: pointer;
}

@media (min-width: 768px) and (max-width: 1200px) {
    .footer-navbar-col p {
        font-size: 12px;
    }
}

@media (min-width: 575px) and (max-width: 767px) {
    .footer-navbar-col p {
        font-size: 12px;
    }
    .section-1-box h1 {
        font-size: 100px !important;
    }
}

@media (max-width: 1200px) and (min-width: 992px) {
    .footer-navbar-col-buttons {
        margin-bottom: -50px !important;
    }
    .footer-navbar-col p {
        text-align: end;
    }
    .react-tooltip {
        margin-left: 300px;
        max-width: 350px;
    }
    .designed-by-box a {
        justify-content: end !important;
        align-items: center !important;
    }
}

@media (max-width: 991px) {
    .footer-navbar-col-buttons {
        justify-content: center !important;
        margin-bottom: 40px;

    }
    .footer-navbar-col p {
        text-align: center;
    }
    .header-nav-bar-brand-name {
        margin-left: 0 !important;
    }
    .section-1-box h1 {
        font-size: 130px;
    }
    .designed-by-box a {
        justify-content: center !important;
        align-items: center !important;
    }
    .navbar-toogle {
        margin-bottom: -10px;
    }
    .message-manager-box {
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
    }
}

@media (min-width: 450px) and (max-width: 574px) {
    .footer-navbar-col p {
        font-size: 12px;
        text-align: center !important;
    }
    .footer-navbar-col-buttons {
        flex-direction: column;
        gap: 1.5em;
    }
    .section-1-box h1 {
        font-size: 70px;
    }
    .react-tooltip {
        max-width: 250px;
    }
}

@media (max-width: 449px) {
    .section-1-box h1 {
        font-size: 50px;
    }
    .footer-navbar-col-buttons {
        flex-direction: column;
        gap: 1.5em;
    }
    .footer-navbar-col p {
        font-size: 10px;
    }
    .message-manager-box {
        max-width: 300px;
    }
    .error-icon {
        font-size: 25px;
    }
    .success-icon {
        font-size: 25px;
    }
}

@media (max-width: 350px) {
    .message-manager-box {
        max-width: 250px;
    }
    .error-icon {
        font-size: 30px;
    }
    .success-icon {
        font-size: 30px;
    }
}

@media (max-width: 1000px) {
    .footer-navbar.collapsed {
        background-color: rgba(23,123,172,0.9);
        width: 100%;
    }
    .header-nav-bar {
        min-width: 100% !important;
    }
}

@media (max-height: 750px) {
    .section-1-box h1 {
        margin-top: -150px !important;
    }
}

@media (max-height: 650px) {
    .section-1-box h1 {
        margin-top: -200px !important;
    }
}

.custom-toggle {
    border-radius: 10px;
}

.custom-collapse {
    border-radius: 10px;
    color: beige;
    text-align: center;
}

.navbar-container {
    margin: 0;
    min-width: 100%;
}

.button:focus:not(:focus-visible) {
    outline: none !important;
}

.navbar-container button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(245, 245, 220, 0.7);
    width: 35px;
    height: 35px;
    padding: 0;
    outline: none;
    border: none;
}

.navbar-container button svg {
    font-size: 30px !important;
}

.navbar-container button span {
    font-size: 17px;
}

.navbar-container button:hover {
    background-color: rgba(245, 245, 220, 0.9);
}

.custom-collapse a {
    color: beige;
    border-radius: 10px;
}

.custom-collapse a:hover {
    color: rgba(245, 245, 220, 0.8);
    border-radius: 10px;
}

.header-nav-bar-brand-name {
    color: beige !important;
    margin-left: 20px;
    font-size: 2em !important;
    max-height: 30px !important;
    margin-top: -0.9em;
}

.header-nav-bar {
    z-index: 5;
    min-width: 98.5%;
    max-height: auto;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    position: fixed;
    background-color: rgba(68, 68, 68, 0.5); 
    backdrop-filter: blur(5px);
}

.header-nav-bar-templates {
    min-width: 100%;
}

.header-nav-bar nav {
    min-width: 100%;
    background-color: transparent !important;
}

.header-nav-bar-toggled {
    background-color: rgba(68, 68, 68, 0.8); 
    backdrop-filter: blur(5px);
}