@import url('https://fonts.googleapis.com/css2?family=Alkatra:wght@500&family=Edu+SA+Beginner&family=Montserrat:ital,wght@1,300&family=Quicksand:wght@300..700&family=Sofia+Sans:wght@700&family=Work+Sans:wght@500&display=swap');

@font-face {
    font-family: 'AltonaSans-Regular';
    src: url('./Typography/AltonaSans-Regular.ttf') format('woff2'),
        url('./Typography/AltonaSans-Regular.ttf') format('woff');
}

@font-face {
    font-family: 'AltonaSans-Italic';
    src: url('./Typography/AltonaSans-Italic.ttf') format('woff2'),
        url('./Typography/AltonaSans-Italic.ttf') format('woff');
}

@font-face {
    font-family: 'Kiss-Boom';
    src: url('./Typography/Kiss-Boom.woff2'),
        url('./Typography/Kiss-Boom.woff');
}

@font-face {
    font-family: 'Kiss-Boom-Italic';
    src: url('./Typography/Kiss-Boom-Italic.woff2'),
        url('./Typography/Kiss-Boom-Italic.woff');
}

@font-face {
    font-family: 'IBM-Plex-Mono-regular';
    src: url('./Typography/IBM-Plex-Mono-Regular.ttf');
}

@font-face {
    font-family: 'IBM-Plex-Mono-Italic';
    src: url('./Typography/IBM-Plex-Mono-Italic.ttf');
}

@font-face {
    font-family: 'Sanchez-Regular';
    src: url('./Typography/Sanchez-Regular.ttf');
}

@font-face {
    font-family: 'Sanchez-Italic';
    src: url('./Typography/Sanchez-Italic.ttf');
}

h3 {
    font-size: 38px !important;
    font-style: normal !important;
    font-optical-sizing: auto !important;
    color: white !important;
    font-weight: 100 !important;
}

p {
    color: white;
    /* text-align: justify; */
    word-spacing: -0.30em;
}

ul {
    color: white;
    text-align: justify;
}

.brand-name-font-style {
    font-family: "Sanchez-Regular", sans-serif;
}