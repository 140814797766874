.button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 15px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    padding: 9px 20px;
    margin: 5px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.5s;
    text-align: center;
    width: auto;
}

.footer-booton {
    border-radius: 20px !important;
    border: solid 1.5px white !important;
    height: 40px !important;
    width: 80px !important;
    color: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    background: rgba(206, 206, 206, 0.2) !important;
    backdrop-filter: blur(5px);
    justify-content: center;
}

.go-back-button-box {
    z-index: 1500;
    position: fixed;
    top: 3%;
    left: 5%;
    transform: translateX(-50%);
    width: 50px;
    height: 40px;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 23px;
    cursor: pointer;
}

.go-back-button-box:hover {
    transition: 0.3s;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 8px;
}

.primary-button {
    background-color: rgb(21, 21, 227);
    color: white;
}

.primary-button:hover {
    background-color: rgb(56, 56, 228);
}

.button.primary-button:disabled,
.button.primary-button[disabled] {
  color: darkgrey;
  cursor: not-allowed;
  opacity: 0.5;
}

.button-red {
    background-color: rgb(255, 0, 0);
    color: white;
}

.button-red:hover {
    background-color:  rgb(232, 54, 54);
}

.beige-button {
    background-color: rgb(238, 235, 216);
}

.beige-button:hover {
    background-color:  rgb(225, 217, 203);
}

.white-button {
    display: flex;
    gap: 20px;
    background-color:  rgb(255, 255, 255);
    color: black;
    box-shadow: none;
}

.white-button:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
    .go-back-button-box {
        width: 35px;
        height: 30px;
        font-size: 17px;
        top: 3%;
        left: 7%;
    }
}