/* app.css styles */

.App {
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
  width: 100%;
}

body {
  font-family: 'IBM-Plex-Mono-regular', sans-serif;
  scroll-behavior: smooth;
  margin: 0;
}

.bottom-spacer {
  height: 143px;
}

@media (max-width: 991px) {
  .bottom-spacer {
    height: 65px;
  }
}

.img-pandito-logo {
  width: 215px;
  height: 215px;
  filter: drop-shadow(2px 2px 7px black);
  cursor: pointer;
}

.img-pandito-logo:hover {
  scale: 1.10;
  transition: 1s;
}

.colum-box {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  gap: 3em;
}

.row-box {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.cursor-box {
  cursor: pointer;
  font-family: 'Quicksand', sans-serif !important;
  font-size: 38px;
  font-style: normal !important;
  font-optical-sizing: auto !important;
  color: white !important;
  font-weight: 100 !important;
}

.cursor-box h3 {
  margin: 0;
}

.info-icon {
  margin-top: 5px;
}

.delete-icon {
  position: absolute;
  top: 5%;
  left: 94%;
  height: auto;
  width: 30px;
  color: red;
  background-color: none;
  border: none;  
}

@media (max-width: 800px) {
  .delete-icon {
    top: 10%;
    left: 92%;
  }
}

@media (max-width: 600px) {
  .delete-icon {
    top: 10%;
    left: 90%;
  }
}

@media (max-width: 400px) {
  .delete-icon {
    left: 85%;
  }
}

.yellow-link-text {
  color: yellow;
  text-decoration: none;
}

.text-box{
  width: 100%;
  padding: 0px 30px;
  justify-content: center;
}

.text-box p {
  font-size: 1rem;
  color: white;
  text-align: justify;
  align-items: center;
  align-self: center;
}

.text-box p svg {
  font-size: 2rem;
  color: wheat;
}

@keyframes pulsate {
  0% {
    transform: scale(1); /* Tamaño normal */
  }
  50% {
    transform: scale3d(1.05,1.05,1.05); /* Aumenta al 5% */
  }
  100% {
    transform: scale(1); /* Vuelve al tamaño normal */
  }
}

.center-child-box {
  animation: pulsate 1.5s infinite; /* Aplica la animación pulsate durante 1.5 segundos, y repítelo infinitamente */
}

/* message styles */

.message {
  font-weight: 600;
  margin: 5px;
  font-size: 16px;
  text-align: center;
}

.message-box {
  position: fixed;
  top: 100px;
}

.error {
  color: red;
}

.success {
  color: green;
}

.submitting {
    color: rgb(64, 154, 186);
}

/* end message styles */

/* Styles while loading the page */

.background-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black; /* Fondo inicial negro */
  animation: backgroundFade 2s ease forwards; /* Animación de transición */
  z-index: -1; /* Colocar detrás del contenido */
}

@keyframes backgroundFade {
  0% {
    background-color: black; /* Fondo inicial negro */
    opacity: 1;
  }
  100% {
    background-color: transparent; /* Fondo transparente al final */
    opacity: 0;
  }
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* end app.css styles */


/* PALETA DE COLORES PANDITO */
/* rgba(242,176,153,255) */ /* SALMON CLARO */
/* rgba(201,124,96,255)  */ /* SALMON OSCURO */

/* rgba(238,229,214,255) */ /* CREMA CLARO */
/* rgba(185,170,151,255) */ /* CREMA OSCURO */

/* rgba(232,223,179,255) */ /* AMARILLO CLARO */
/* rgba(189,170,111,255) */ /* AMARILLO OSCURO */

/* rgba(164,195,173,255) */ /* VERDE CLARO */
/* rgba(132,159,124,255) */ /* VERDE OSCURO */

/* rgba(119,206,225,255) */ /* AZUL CLARO */
/* rgba(23,123,172,255) */ /* AZUL OSCURO */